import { useEffect } from "react";
import { useState } from "react";
import Chart from "../components/Chart";
import Login from "./Login";

function Home() {
  const [loginInfo, setLoginInfo] = useState<string | null>(null);

  useEffect(() => {
    const auth = localStorage.getItem("chart-infori-auth");
    setLoginInfo(auth);
  }, [loginInfo]);

  if (loginInfo == null) {
    return <Login setLoginInfo={setLoginInfo} />;
  }

  const handleLogout = () => {
    localStorage.removeItem("chart-infori-auth");
    setLoginInfo(null);
  };

  return (
    <div>
      <nav
        className="navbar is-dark"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            INFORIENCE CHART
          </a>
        </div>
        <div className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item" href="/">
              Home/Clear
            </a>
          </div>
          <div className="navbar-end">
            <a className="navbar-item" onClick={() => handleLogout()}>
              Logout
            </a>
          </div>
        </div>
      </nav>
      <div className="container my-5">
        <Chart />
      </div>
    </div>
  );
}

export default Home;
