import { ClusterAttribute } from "../models/Chart";

import "./ChartDetail.scss";

type Props = {
  detail: ClusterAttribute;
};

function ClusterNewsLink({ detail }: Props) {
  return (
    <div className="columns">
      <div className="column is-three-quarters">
        <div className="card">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>주요 문서</th>
              </tr>
            </thead>
            <tbody>
              {detail.highTitles.map((i: any) => {
                return (
                  <tr key={i.url}>
                    <td>
                      <a href={i.url} target="_blank" rel="noreferrer">
                        <span className="is-size-7">🔗</span> {i.title}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ClusterNewsLink;
