import { useState } from "react";
import axios from "axios";
import { ClusterAttribute } from "../models/Chart";

import "./ChartDetail.scss";

type Props = {
  detail: ClusterAttribute;
};

type KewyordDetail =
  | {
      category: string;
      keyword: string;
      links: [{ title: string; url: string }];
    }
  | undefined;

function ClusterInformation({ detail }: Props) {
  const [keywordDetail, setKeywordDetail] = useState<KewyordDetail>(undefined);
  const [processDetail, setProcessDetail] = useState(false);

  const handleSearchKeywordDetail = async (
    category: string,
    keyword: string
  ) => {
    setKeywordDetail(undefined);
    setProcessDetail(true);

    const url = `/api/searchClusterDetailedView/${keyword}&${detail.clusterPeriod}&${detail.clusterIndex}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data[0];
      const responseKeywordDetail = {
        category: category,
        keyword: responseData.query,
        links: responseData.searched_title_url_in_cluster.map((i: string[]) => {
          return { title: i[0], url: i[1] };
        }),
      };
      setKeywordDetail(responseKeywordDetail);
    } catch (e) {
      alert(
        "상세 키워드 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setKeywordDetail(undefined);
    } finally {
      setProcessDetail(false);
    }
  };

  const handleMergedKeywordDetail = async (
    category: string,
    keyword: string
  ) => {
    setKeywordDetail(undefined);
    setProcessDetail(true);

    const url = `/api/searchClusterExceptRelatedView/${keyword}&${detail.clusterPeriod}&${detail.clusterIndex}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data[0];
      const responseKeywordDetail = {
        category: category,
        keyword: responseData.query,
        links: responseData.searched_title_url_in_cluster.map((i: string[]) => {
          return { title: i[0], url: i[1] };
        }),
      };
      setKeywordDetail(responseKeywordDetail);
    } catch (e) {
      alert(
        "상세 키워드 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setKeywordDetail(undefined);
    } finally {
      setProcessDetail(false);
    }
  };

  return (
    <div className="columns">
      <div className="column">
        <div className="card">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>주요 장소</th>
              </tr>
            </thead>
            <tbody>
              {detail.highLocations.map((i: any) => {
                let trClass = "has-text-link ";
                if (
                  keywordDetail &&
                  keywordDetail.category ===
                    "선택한 시점의 주제 내에서 이 장소가 언급된 문서 리스트" &&
                  keywordDetail.keyword === i
                ) {
                  trClass = "is-selected";
                }

                return (
                  <tr key={i} className={trClass}>
                    <td
                      className="is-clickable"
                      onClick={() =>
                        handleSearchKeywordDetail(
                          "선택한 시점의 주제 내에서 이 장소가 언급된 문서 리스트",
                          i
                        )
                      }
                    >
                      {i}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>주요 기관</th>
              </tr>
            </thead>
            <tbody>
              {detail.highOrganizations.map((i: any) => {
                let trClass = "has-text-link ";
                if (
                  keywordDetail &&
                  keywordDetail.category ===
                    "선택한 시점 & 주제 내에서 이 기관이 언급된 문서 리스트" &&
                  keywordDetail.keyword === i
                ) {
                  trClass = "is-selected";
                }

                return (
                  <tr key={i} className={trClass}>
                    <td
                      className="is-clickable"
                      onClick={() =>
                        handleSearchKeywordDetail(
                          "선택한 시점 & 주제 내에서 이 기관이 언급된 문서 리스트",
                          i
                        )
                      }
                    >
                      {i}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>주요 인물</th>
              </tr>
            </thead>
            <tbody>
              {detail.highPeople.map((i: any) => {
                let trClass = "has-text-link ";
                if (
                  keywordDetail &&
                  keywordDetail.category ===
                    "선택한 시점 & 주제 내에서 이 인물이 언급된 문서 리스트" &&
                  keywordDetail.keyword === i
                ) {
                  trClass = "is-selected";
                }

                return (
                  <tr key={i} className={trClass}>
                    <td
                      className="is-clickable"
                      onClick={() =>
                        handleSearchKeywordDetail(
                          "선택한 시점 & 주제 내에서 이 인물이 언급된 문서 리스트",
                          i
                        )
                      }
                    >
                      {i}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>새로운 키워드</th>
              </tr>
            </thead>
            <tbody>
              {detail.mergedWords.map((i: any) => {
                let trClass = "has-text-link ";
                if (
                  keywordDetail &&
                  keywordDetail.category ===
                    "선택한 시점 & 주제 내에서 이 새로운 키워드가 언급된 문서 리스트" &&
                  keywordDetail.keyword === i
                ) {
                  trClass = "is-selected";
                }

                return (
                  <tr key={i} className={trClass}>
                    <td
                      className="is-clickable"
                      onClick={() =>
                        handleMergedKeywordDetail(
                          "선택한 시점 & 주제 내에서 이 새로운 키워드가 언급된 문서 리스트",
                          i
                        )
                      }
                    >
                      {i}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="column is-half">
        {processDetail && (
          <progress className="progress is-medium" max="100">
            Processing
          </progress>
        )}
        {keywordDetail && (
          <div className="card">
            <article className="message">
              <div className="message-header">
                <p>
                  {keywordDetail.category} : {keywordDetail.keyword}
                </p>
                <button
                  className="delete"
                  aria-label="delete"
                  onClick={() => setKeywordDetail(undefined)}
                ></button>
              </div>
              <div className="message-body">
                <ul>
                  {keywordDetail.links.map((i) => {
                    return (
                      <li key={i.url} className="keyword-link has-text-link">
                        <a href={i.url} target="_blank" rel="noreferrer">
                          <span className="is-size-7">🔗</span> {i.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </article>
          </div>
        )}
      </div>
    </div>
  );
}
export default ClusterInformation;
