import axios from "axios";
import { useEffect, useState, FormEvent, MouseEvent } from "react";
import { ResponsiveScatterPlotCanvas } from "@nivo/scatterplot";
import { ResponsiveLine } from "@nivo/line";
import { UrlLink } from "../models/Cluster";
import { ClusterAttribute } from "../models/Chart";
import ClusterInformation from "./ClusterInformation";
import ClusterNewsLink from "./ClusterNewsLink";

import "./Chart.scss";

type ClusterDatum = {
  id: number;
  x: number;
  y: number;
  size: number;
  title: string;
  clusterIndex: number;
  periodNum: number;
  indexLabel: string;
  periodLabel: string;
  documentCount: number;
};

type ClusterRelated = {
  periodNum: number;
  periodLabel: string;
  clusterNum: number;
  clusterLabel: string;
  relatedWords: string[];
  relatedLinks: UrlLink[];
};

type ClusterRelatedKeyword = {
  keyword: string;
  links: UrlLink[];
};

type PeriodDatum = {
  x: number;
  y: number;
  periodNum: number;
  searchedNum: number;
  searchedNumRatio: number;
  totalDocNum: number;
};

type PeriodDetail = {
  periodNum: number;
  mostRelatedWords: string[];
  links: UrlLink[];
};

enum TabMenu {
  "CLUSTER_SEARCH_RESULT" = "전체 검색 결과",
  "CLUSTER_INFORMATION" = "선택한 시점 & 주제 내 상세 정보",
  "CLUSTER_NEWS_LINKS" = "선택한 시점 & 주제 내 주요 문서",
  "CLOSE" = "[X]",
}

function Chart() {
  const [clusterData, setClusterData] = useState(Array<ClusterDatum>());
  const [clusterResult, setClusterResult] = useState(Array<UrlLink>());
  const [clusterKeyword, setClusterKeyword] = useState("");
  const [clusterRelated, setClusterRelated] = useState<
    ClusterRelated | undefined
  >(undefined);
  const [selectedClusterRelatedKeyword, setSelectedClusterRelatedKeyword] =
    useState<ClusterRelatedKeyword | undefined>(undefined);
  const [processClusterRelatedKeyword, setProcessClusterRelatedKeyword] =
    useState(false);
  const [clusterAttribute, setClusterAttribute] = useState<
    ClusterAttribute | undefined
  >(undefined);
  const [keyword, setKeyword] = useState("");
  const [processPeriodRatios, setProcessPeriodRatios] = useState(false);
  const [periodRatios, setPeriodRatios] = useState(Array<PeriodDatum>());
  const [periodDetail, setPeriodDetail] = useState<PeriodDetail | undefined>(
    undefined
  );
  const [selectedPeriodRelatedKeyword, setSelectedPeriodRelatedKeyword] =
    useState<ClusterRelatedKeyword | undefined>(undefined);
  const [tabMenu, setTabMenu] = useState<TabMenu | undefined>(undefined);

  const handleSearch = async (event: FormEvent) => {
    event.preventDefault();

    let clusters = Array<any>();
    try {
      if (keyword && keyword !== "") {
        const clusterUrl = "/api/searchClusterView/" + keyword;
        const clusterResponse = await axios.get(clusterUrl);
        clusters = clusterResponse.data;

        const resultUrl = `/api/searchResultView/${keyword}`;
        const resultResponse = await axios.get(resultUrl);
        const resultData = resultResponse.data.find(() => true);
        if (resultData) {
          const results = resultData.searched_title_url_in_all.map((i: any) => {
            return { title: i[0], url: i[1] };
          });
          setClusterResult(results);
          setClusterKeyword(keyword);
        }
      } else {
        setClusterResult([]);
        setClusterKeyword("");
      }
      setTabMenu(TabMenu.CLUSTER_SEARCH_RESULT);
    } catch (e) {
      setClusterResult([]);
      setTabMenu(undefined);
      alert(
        "데이터 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      clusters = [];
    }

    const data = clusterData.map((i) => {
      let title = undefined;
      const matchedDatum = clusters.find(
        (r: any) =>
          r.period_num === i.periodNum && r.cluster_index === i.clusterIndex
      );
      if (matchedDatum) {
        title = matchedDatum.searched_num_in_cluster.toString();
      }

      return {
        id: i.id,
        x: i.x,
        y: i.y,
        size: i.size,
        title: title,
        clusterIndex: i.clusterIndex,
        periodNum: i.periodNum,
        indexLabel: i.indexLabel,
        periodLabel: i.periodLabel,
        documentCount: i.documentCount,
      };
    });
    setClusterData(data);
    setClusterRelated(undefined);
    setSelectedClusterRelatedKeyword(undefined);
    setClusterAttribute(undefined);

    setProcessPeriodRatios(false);
    setPeriodRatios([]);
    setPeriodDetail(undefined);
  };

  const handleAnalysisPeriodRatio = async (event: MouseEvent) => {
    event.preventDefault();

    setProcessPeriodRatios(true);

    try {
      if (keyword && keyword !== "") {
        if (clusterKeyword !== keyword) {
          handleSearch(event);
        }

        const url = "/api/searchRatioView/" + keyword;
        const response = await axios.get(url);
        const responseData = response.data ?? [];
        const ratios = responseData.map((i: any) => {
          return {
            x: i.period_num,
            y: i.searched_num_ratio,
            periodNum: i.period_num,
            searchedNum: i.searched_num_in_period,
            searchedNumRatio: i.searched_num_ratio,
            totalDocNum: i.total_doc_num_in_period,
          };
        });
        setPeriodRatios(ratios);
      }
    } catch (e) {
      alert(
        "데이터 분석 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setPeriodRatios([]);
    } finally {
      setProcessPeriodRatios(false);
    }
  };

  const handleSearchClusterRelatedKeywordDetail = async (
    clusterNum: number,
    periodNum: number,
    relatedKeyword: string
  ) => {
    setSelectedClusterRelatedKeyword(undefined);
    setProcessClusterRelatedKeyword(true);

    const url = `/api/searchClusterDetailedViewComplex/${keyword}and${relatedKeyword}&${periodNum}&${clusterNum}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data[0];
      const responseKeywordDetail = {
        keyword: responseData.query,
        links: responseData.searched_title_url_in_cluster.map((i: string[]) => {
          return { title: i[0], url: i[1] };
        }),
      };
      setSelectedClusterRelatedKeyword(responseKeywordDetail);
    } catch (e) {
      alert(
        "상세 키워드 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setSelectedClusterRelatedKeyword(undefined);
    } finally {
      setProcessClusterRelatedKeyword(false);
    }
  };

  const handleSearchPeriodRelatedKeywordDetail = async (
    periodNum: number,
    relatedKeyword: string
  ) => {
    const url = `/api/searchPeriodDetailedViewComplex/${keyword}and${relatedKeyword}&${periodNum}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data[0];
      const responseKeywordDetail = {
        keyword: responseData.query,
        links: responseData.searched_title_url_in_period.map((i: string[]) => {
          return { title: i[0], url: i[1] };
        }),
      };
      setSelectedPeriodRelatedKeyword(responseKeywordDetail);
    } catch (e) {
      alert(
        "상세 키워드 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setSelectedPeriodRelatedKeyword(undefined);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/initialDraw/");
        const data = response.data.map((d: any) => {
          return {
            id: `${d.period_num}_${d.cluster_index}`,
            x: d.period_num,
            y: d.cluster_index,
            size: Math.max(d.cluster_size, 1),
            clusterIndex: d.cluster_index,
            periodNum: d.period_num,
            indexLabel: d.cluster_index_label,
            periodLabel: d.period_num_label,
            documentCount: d.num_of_docs,
          };
        });
        setClusterData(data);
      } catch (e) {
        alert(
          "데이터 조회 중 오류가 발생하였습니다. 반복적으로 발생 시 운영자에게 문의주세요."
        );
      }
    };
    fetchData();
  }, []);

  const margin = { top: 40, right: 40, bottom: 40, left: 150 };
  const colors = [
    "#FF6633",
    "#FFB399",
    "#00B3E6",
    "#E6B333",
    "#999966",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const series = [
    {
      id: "Cluster",
      data: clusterData,
    },
  ];
  const lineSeries = [
    {
      id: "Period Ratio",
      data: periodRatios,
    },
  ];

  const xAxisLabels = clusterData.reduce((a, b) => {
    a.set(b.periodNum, b.periodLabel);
    return a;
  }, new Map<Number, String>());

  const yAxisLabels = clusterData.reduce((a, b) => {
    a.set(b.clusterIndex, b.indexLabel);
    return a;
  }, new Map<Number, String>());

  return (
    <div>
      <form onSubmit={handleSearch}>
        <div className="field is-grouped">
          <div className="field has-addons">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="검색어"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="control">
              <button type="submit" className="button is-info">
                전체 검색
              </button>
            </div>
          </div>
          <div className="field ml-3">
            <button
              type="button"
              className="button is-primary"
              onClick={handleAnalysisPeriodRatio}
              disabled={keyword === "" || processPeriodRatios}
            >
              시점에 따른 검색어의 중요도 흐름 계산
              {processPeriodRatios && <span className="ml-2">(처리중...)</span>}
            </button>
          </div>
        </div>
      </form>

      <nav className="panel">
        <p className="panel-heading">
          시점에 따른 주제 구성
          {clusterAttribute && (
            <span className="tag is-normal ml-5">
              (시점: {clusterAttribute.clusterPeriodLabel}, 주제:
              {clusterAttribute.clusterIndexLabel})
            </span>
          )}
        </p>
        <div className="panel-block">
          <div className="control">
            <div id="infori-plot">
              <ResponsiveScatterPlotCanvas
                data={series}
                margin={margin}
                axisBottom={{
                  legend: "시점",
                  legendPosition: "middle",
                  legendOffset: 32,
                  format: (v) => xAxisLabels.get(v) ?? "",
                }}
                axisLeft={{
                  legend: "주제",
                  legendPosition: "middle",
                  legendOffset: -80,
                  format: (v) => yAxisLabels.get(v) ?? "",
                }}
                xScale={{
                  type: "linear",
                  min: 0,
                  max: Math.max(...clusterData.map((i) => i.x)),
                }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: Math.max(...clusterData.map((i) => i.y)),
                }}
                renderNode={(ctx, node) => {
                  const data = node.data as ClusterDatum;

                  let pointSize = Math.ceil(data.size / 2.3);
                  if (pointSize > 0) {
                    pointSize = Math.max(pointSize, 3);
                  }

                  let color = colors[Math.floor(data.y)];
                  if (data.title && data.title !== "") {
                    let opacity = Number(data.title);
                    if (data.title !== "0") {
                      opacity = opacity * 5 + 35;
                    } else {
                      opacity = 15;
                    }
                    if (opacity > 255) {
                      opacity = 255;
                    }

                    color =
                      color +
                      opacity.toString(16).padStart(2, "0").toUpperCase();
                  }

                  ctx.beginPath();
                  ctx.arc(node.x, node.y, pointSize, 0, 2 * Math.PI);
                  ctx.fillStyle = color;
                  ctx.fill();

                  if (data.title && data.title !== "") {
                    ctx.font = "14px Arial";
                    ctx.fillStyle = "black";
                    ctx.fillText(
                      data.title,
                      node.x - 5 + (4 - data.title.length * 3),
                      node.y + 5
                    );
                  }
                }}
                layers={[
                  "grid",
                  "axes",
                  "nodes",
                  "mesh",
                  "legends",
                  "annotations",
                ]}
                tooltip={(props) => {
                  return (
                    <article className="message is-success">
                      <div className="message-header p-1">
                        <span className="icon-text is-size-7">
                          <span className="icon">
                            <i className="fas fa-location-pin"></i>
                          </span>
                          <span>문서: {props.node.data.documentCount}건</span>
                        </span>
                      </div>
                      <div className="message-body p-2">
                        <ul className="ml-1 is-size-7">
                          <li>{props.node.data.indexLabel}</li>
                          <li>{props.node.data.periodLabel}</li>
                        </ul>
                      </div>
                    </article>
                  );
                }}
                onClick={async ({ data }: any) => {
                  if (
                    clusterAttribute?.clusterIndex === data.clusterIndex &&
                    clusterAttribute?.clusterPeriod === data.periodNum
                  ) {
                    setClusterAttribute(undefined);
                    return;
                  }

                  const url = `/api/clusterAttributeView/${data.periodNum}&${data.clusterIndex}`;
                  const response = await axios.get(url);
                  const result = response.data.find(
                    (d: any) =>
                      d.period_num === data.periodNum &&
                      d.cluster_index === data.clusterIndex
                  );
                  if (result) {
                    setClusterAttribute({
                      clusterPeriod: data.periodNum,
                      clusterIndex: data.clusterIndex,
                      clusterPeriodLabel: data.periodLabel,
                      clusterIndexLabel: data.indexLabel,
                      highLocations:
                        result.high_location_cluster_tfidf_max_list,
                      highOrganizations:
                        result.high_organization_cluster_tfidf_max_list,
                      highPeople: result.high_person_cluster_tfidf_max_list,
                      highTitles: result.title_url_in_cluster.map(
                        (i: string[]) => {
                          return { title: i[0], url: i[1] };
                        }
                      ),
                      mergedWords: result.new_emerged_words_in_cluster,
                    });
                    if (
                      tabMenu !== TabMenu.CLUSTER_INFORMATION &&
                      tabMenu !== TabMenu.CLUSTER_SEARCH_RESULT
                    ) {
                      setTabMenu(TabMenu.CLUSTER_INFORMATION);
                    }
                  } else {
                    setClusterAttribute(undefined);
                  }

                  if (keyword) {
                    const relatedUrl = `/api/searchClusterRelatedView/${keyword}&${data.periodNum}&${data.clusterIndex}`;
                    const relatedResponse = await axios.get(relatedUrl);
                    const relatedData = relatedResponse.data.find(() => true);
                    if (relatedData) {
                      const relatedWords = relatedData.most_related_words;
                      const relatedLinks =
                        relatedData.searched_title_url_in_cluster.map(
                          (i: any) => {
                            return { title: i[0], url: i[1] };
                          }
                        );
                      setClusterRelated({
                        periodNum: data.periodNum,
                        periodLabel: data.periodLabel,
                        clusterNum: data.clusterIndex,
                        clusterLabel: data.indexLabel,
                        relatedWords,
                        relatedLinks,
                      });
                    }
                  }
                }}
              />
            </div>
            <div className="container m-5">
              <div className="tabs">
                <ul>
                  {(clusterResult.length > 0 ||
                    (clusterAttribute && tabMenu)) &&
                    Object.values(TabMenu)
                      .filter(
                        (_, i) =>
                          !(
                            (i === 0 && clusterResult.length <= 0) ||
                            (i > 0 && clusterAttribute === undefined)
                          )
                      )
                      .map((t) => {
                        return (
                          <li
                            className={`${tabMenu === t ? "is-active" : ""}`}
                            key={t}
                          >
                            {/* eslint-disable-next-line */}
                            <a onClick={() => setTabMenu(t)}>
                              {t !== TabMenu.CLOSE ? (
                                t
                              ) : (
                                <span className="icon">
                                  <i className="fas fa-toggle-on"></i>
                                </span>
                              )}
                            </a>
                          </li>
                        );
                      })}
                </ul>
              </div>
              {tabMenu === TabMenu.CLUSTER_SEARCH_RESULT &&
                clusterResult.length > 0 && (
                  <div className="columns">
                    <div className="column is-half">
                      <div className="card">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>
                                검색 결과
                                <span className="ml-4 is-size-7 has-text-grey">
                                  (Keyword: {keyword})
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {clusterResult.map((i) => {
                              return (
                                <tr key={i.url}>
                                  <td>
                                    <a
                                      href={i.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className="is-size-7">🔗</span>{" "}
                                      {i.title}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {clusterRelated && (
                      <>
                        <div className="column is-half">
                          <div className="card">
                            <table className="table is-fullwidth">
                              <thead>
                                <tr>
                                  <th>
                                    선택한 시점 &amp; 주제 내에서 질의어와
                                    관련된 키워드 리스트
                                    <span className="ml-4 is-size-7 has-text-grey">
                                      (시점: {clusterRelated.periodLabel}, 주제:{" "}
                                      {clusterRelated.clusterLabel})
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {clusterRelated.relatedWords.map((i) => {
                                  let trClass = "has-text-link ";
                                  if (
                                    selectedClusterRelatedKeyword?.keyword === i
                                  ) {
                                    trClass = "is-selected";
                                  }

                                  return (
                                    <tr key={i} className={trClass}>
                                      <td
                                        className="is-clickable"
                                        onClick={() =>
                                          handleSearchClusterRelatedKeywordDetail(
                                            clusterRelated.clusterNum,
                                            clusterRelated.periodNum,
                                            i
                                          )
                                        }
                                      >
                                        {i}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {clusterRelated.relatedWords.length === 0 && (
                                  <tr>
                                    <td className="has-text-grey">
                                      질의어와 관련된 콘텐츠가 충분하지
                                      않습니다.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {processClusterRelatedKeyword && (
                              <div className="m-5">
                                <progress
                                  className="progress is-medium"
                                  max="100"
                                >
                                  Processing
                                </progress>
                              </div>
                            )}
                            {selectedClusterRelatedKeyword && (
                              <div className="m-5">
                                <article className="message">
                                  <div className="message-header">
                                    <p>
                                      선택한 시점 &amp; 주제 내에서 질의어와
                                      관련 키워드가 언급된 문서 리스트:{" "}
                                      {selectedClusterRelatedKeyword.keyword}
                                    </p>
                                    <button
                                      className="delete"
                                      aria-label="delete"
                                      onClick={() =>
                                        setSelectedClusterRelatedKeyword(
                                          undefined
                                        )
                                      }
                                    ></button>
                                  </div>
                                  <div className="message-body">
                                    <ul>
                                      {selectedClusterRelatedKeyword.links
                                        .length === 0 && <li>없음</li>}
                                      {selectedClusterRelatedKeyword.links.map(
                                        (i) => {
                                          return (
                                            <li
                                              key={i.url}
                                              className="keyword-link has-text-link"
                                            >
                                              <a
                                                href={i.url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <span className="is-size-7">
                                                  🔗
                                                </span>{" "}
                                                {i.title}
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </article>
                              </div>
                            )}
                            <table className="table is-fullwidth mt">
                              <thead>
                                <tr>
                                  <th>
                                    선택한 시점 &amp; 주제 내에서 질의어가
                                    언급된 문서 리스트
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {clusterRelated.relatedLinks.map((i) => {
                                  return (
                                    <tr key={i.url}>
                                      <td>
                                        <a
                                          href={i.url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <span className="is-size-7">🔗</span>{" "}
                                          {i.title}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {clusterRelated.relatedLinks.length === 0 && (
                                  <tr>
                                    <td className="has-text-grey">
                                      질의어가 언급된 문서가 없습니다.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              {clusterAttribute && (
                <>
                  {tabMenu === TabMenu.CLUSTER_INFORMATION && (
                    <ClusterInformation detail={clusterAttribute} />
                  )}
                  {tabMenu === TabMenu.CLUSTER_NEWS_LINKS && (
                    <ClusterNewsLink detail={clusterAttribute} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      {processPeriodRatios && (
        <progress className="progress is-medium is-primary" max="100">
          Processing
        </progress>
      )}

      {!processPeriodRatios && periodRatios.length > 0 && (
        <nav className="panel">
          <p className="panel-heading">
            시점에 따른 검색어의 비중
            <span className="subtitle is-7 ml-3">({keyword})</span>
          </p>
          <div className="panel-block">
            <div className="control">
              <div id="period-ratio-plot">
                <ResponsiveLine
                  data={lineSeries}
                  margin={margin}
                  useMesh={true}
                  axisBottom={{
                    legend: "시점",
                    legendPosition: "middle",
                    legendOffset: 32,
                    format: (v) => xAxisLabels.get(v) ?? "",
                  }}
                  xScale={{
                    type: "linear",
                    min: 0,
                    max: Math.max(...clusterData.map((i) => i.x)),
                  }}
                  tooltip={(props) => {
                    return (
                      <article className="message is-success">
                        <div className="message-body p-2">
                          <ul className="ml-1 is-size-7">
                            <li>
                              시점:{" "}
                              {xAxisLabels.get(
                                Number(props.point.data.xFormatted)
                              ) ?? ""}
                            </li>
                            <li>비중: {props.point.data.yFormatted}</li>
                          </ul>
                        </div>
                      </article>
                    );
                  }}
                  onClick={async ({ data }: any) => {
                    const url = `/api/relatedKeywordView/${keyword}&${data.periodNum}`;
                    const response = await axios.get(url);
                    const result = response.data.find(
                      (i: any) => i.period_num === data.periodNum
                    );
                    if (result) {
                      setPeriodDetail({
                        periodNum: data.periodNum,
                        mostRelatedWords: result.most_related_words,
                        links: result.searched_title_url_in_period.map(
                          (t: any) => {
                            return {
                              title: t[0],
                              url: t[1],
                            };
                          }
                        ),
                      });
                    } else {
                      setPeriodDetail(undefined);
                    }
                  }}
                ></ResponsiveLine>
              </div>
              {periodDetail && (
                <div className="container m-5">
                  <div className="my-4">
                    <span className="title is-5 has-text-grey">
                      선택한 시점의 세부 정보
                    </span>
                    <span className="subtitle is-7 ml-3">
                      (시점: {xAxisLabels.get(periodDetail.periodNum) ?? ""})
                    </span>
                  </div>
                  <div className="columns">
                    <div className="column is-half">
                      <div className="card">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>
                                선택한 시점에서 검색어와 관련된 키워드 리스트
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {periodDetail.mostRelatedWords.map((i) => {
                              let trClass = "has-text-link ";
                              if (selectedPeriodRelatedKeyword?.keyword === i) {
                                trClass = "is-selected";
                              }

                              return (
                                <tr key={i} className={trClass}>
                                  <td
                                    className="is-clickable"
                                    onClick={() =>
                                      handleSearchPeriodRelatedKeywordDetail(
                                        periodDetail.periodNum,
                                        i
                                      )
                                    }
                                  >
                                    {i}
                                  </td>
                                </tr>
                              );
                            })}
                            {periodDetail.mostRelatedWords.length === 0 && (
                              <tr>
                                <td>
                                  질의어와 관련된 콘텐츠가 충분하지 않습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {selectedPeriodRelatedKeyword && (
                          <div className="card">
                            <article className="message">
                              <div className="message-header">
                                <p>
                                  선택한 시점에서 검색어 및 관련 키워드가 모두
                                  언급된 문서 리스트 :{" "}
                                  {selectedPeriodRelatedKeyword.keyword}
                                </p>
                                <button
                                  className="delete"
                                  aria-label="delete"
                                  onClick={() =>
                                    setSelectedPeriodRelatedKeyword(undefined)
                                  }
                                ></button>
                              </div>
                              <div className="message-body">
                                <ul>
                                  {selectedPeriodRelatedKeyword.links.map(
                                    (i) => {
                                      return (
                                        <li
                                          key={i.url}
                                          className="keyword-link has-text-link"
                                        >
                                          <a
                                            href={i.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <span className="is-size-7">
                                              🔗
                                            </span>{" "}
                                            {i.title}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </article>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="column is-half">
                      <div className="card">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>
                                선택한 시점에서 검색어가 언급된 문서 리스트
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {periodDetail.links.map((i) => {
                              return (
                                <tr key={i.url}>
                                  <td>
                                    <a
                                      href={i.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className="is-size-7">🔗</span>{" "}
                                      {i.title}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                            {periodDetail.links.length === 0 && (
                              <tr>
                                <td>질의어가 언급된 문서가 없습니다.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      )}

      <div className="is-size-7 has-text-grey">
        <div className="content has-text-centered">
          <p>
            Copyright 2022.{" "}
            <a
              href="https://inforience.net"
              className="has-text-grey has-text-weight-bold"
            >
              Inforience
            </a>{" "}
            Inc. all rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Chart;
